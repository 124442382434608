<template>
  <en-dialog :model-value="modelValue" width="80%" title="估价单列表" @close="$emit('update:model-value', false)">
    <tabs-maintain
      v-model="tabs.active"
      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['SVQTSTAT']) }"
      :props="{ label: 'message', value: 'code' }"
      :method="table.get"
    ></tabs-maintain>

    <table-dynamic
      :height="600"
      code="SVSQT"
      :data="table.data"
      :loading="table.loading"
      :paging="table.paging"
      :method="table.get"
      pagination
      :config="table.config"
    >
      <template #FREIGHT_DOCUMENT="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationQueryDto'] }"> </template>

      <template #VEHICLE_SPEC="{ row }: { row: EnocloudServiceDefinitions['ServiceQuotationQueryDto'] }">
        {{ row.vehicleSpec?.split('^').join(',') }}
      </template>
    </table-dynamic>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      tabs: {
        active: ''
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/quotation/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { status: this.tabs.active }
            }
          }
        },
        config: {
          OPERATION: { visible: false },
          SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } },
          VIN: { header: { filter: { type: 'text', field: 'vin' } } },
          CUSTOMER_NAME: { header: { filter: { type: 'text', field: 'customerName' } } },
          PLATE_NO: { header: { filter: { type: 'text', field: 'plateNo' } } },
          ADVISOR_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'advisorId',
                props: {
                  remote: true,
                  props: { label: 'name', value: 'id' },
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params(params, name) {
                      params.payload = { name, includingDestroyed: true, advisorFirst: true }
                    }
                  }
                }
              }
            }
          },
          PREPARED_DATETIME: { header: { filter: { type: 'date', field: ['preparedStartDate', 'preparedEndDate'], props: { type: 'daterange' } } } }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
